import React from "react";

const Icon8Checkmark = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 30 30"
    width="30px"
    height="50px"
  >
    <path d="M13.44,20.56c-0.265,0-0.52-0.105-0.707-0.293l-4.453-4.453c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l3.746,3.746l10.8-10.8C22.039,4.691,18.718,3,15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12c0-2.18-0.59-4.218-1.606-5.98L14.147,20.267C13.96,20.454,13.706,20.56,13.44,20.56z" />
    <path d="M27.707,5.293c-0.391-0.391-1.023-0.391-1.414,0L24.24,7.346c0.432,0.521,0.813,1.085,1.154,1.675l2.313-2.313C28.098,6.316,28.098,5.684,27.707,5.293z" />
  </svg>
);

export default Icon8Checkmark;
