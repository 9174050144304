const SvgIcons8Property = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M15 40c-2.07-2.114-5.727-5.727-6-6-1-1-1-2 0-3s2-1 3 0c.455.455 5.46 5.449 6 6 2.16 2.114 3 4.324 3 8v2"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M13 47h0s-6.94-8.314-8.02-9.6C3.81 36.114 3 35.654 3 32.07V19c0-1 1-2 2-2s2 1 2 2v12l1 1M29 47v-2c0-3.676.84-5.886 3-8 .54-.551 5.545-5.545 6-6 1-1 2-1 3 0s1 2 0 3c-.273.273-3.93 3.886-6 6"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m42 32 1-1V19c0-1 1-2 2-2s2 1 2 2v13.07c0 3.584-.81 4.044-1.98 5.33C43.94 38.686 37 47 37 47h0M36 10v17H14V10"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      fill="currentColor"
      d="M40 10.663 25.507 2.138a1 1 0 0 0-1.014 0L10 10.663v2.321L25 4.16l15 8.824v-2.321z"
    />
  </svg>
);
export default SvgIcons8Property;
