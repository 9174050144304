const SvgIcons8Maybe = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    style={{
      enableBackground: "new 0 0 50 50",
    }}
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20 7v2h30V7H20zm0 16v2h30v-2H20zM20 40h30v2H20zM11.703 4.344 7.859 8.625 5.109 6.25l-1.313 1.5 3.5 3 .75.656.656-.75 4.5-5-1.499-1.312z"
    />
    <path
      d="m5 19.899 7 7M12 19.899l-7 7"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeMiterlimit: 10,
      }}
    />
    <path
      fill="currentColor"
      d="M7.972 44.415c-.864 0-1.445.561-1.445 1.396 0 .83.581 1.388 1.445 1.388.878 0 1.444-.545 1.444-1.388 0-.848-.566-1.396-1.444-1.396zM8.198 35c-2.33 0-3.822 1.307-3.894 3.411l-.004.103h2.102l.006-.093c.063-.942.708-1.527 1.684-1.527.944 0 1.604.556 1.604 1.351 0 .743-.304 1.152-1.297 1.752-1.185.695-1.638 1.476-1.565 2.689l.01.649h2.079v-.563c0-.771.282-1.146 1.313-1.752C11.423 40.311 12 39.379 12 38.17 12 36.304 10.437 35 8.198 35z"
    />
  </svg>
);
export default SvgIcons8Maybe;
