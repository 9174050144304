const Icons8Apartment = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M35.5 3 25 15v30h21V15L35.5 3zM14.5 3 4 15v30h21V15L14.5 3z"
      style={{
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M29 18h4v5h-4zM38 18h4v5h-4zM29 27h4v5h-4zM38 27h4v5h-4zM29 36h4v5h-4zM38 36h4v5h-4zM8 18h4v5H8zM17 18h4v5h-4zM8 27h4v5H8zM17 27h4v5h-4zM8 36h4v5H8zM17 36h4v5h-4z"
      style={{
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);
export default Icons8Apartment;
