import * as React from "react";
const SvgIcons8SmokeDetector = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M28.5 4h-25A2.5 2.5 0 0 0 1 6.5v3c0 1.27.95 2.31 2.17 2.47l1.52 7.62C4.97 20.99 6.21 22 7.63 22h16.72c1.43 0 2.66-1.01 2.94-2.41l1.52-7.62c1.22-.16 2.17-1.2 2.17-2.47v-3a2.5 2.5 0 0 0-2.5-2.5Zm-8 16h-9v-3c0-.55.45-1 1-1h7c.55 0 1 .45 1 1v3Zm4.84-.8c-.09.47-.51.8-.98.8H22.5v-3c0-1.65-1.35-3-3-3h-7c-1.65 0-3 1.35-3 3v3H7.64c-.48 0-.89-.34-.98-.8L5.22 12h21.56l-1.44 7.2ZM29 9.5c0 .28-.22.5-.5.5h-25c-.28 0-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5h25c.28 0 .5.22.5.5v3Z" />
    <path d="M17.5 17h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1ZM7 7H6c-.55 0-1 .45-1 1s.45 1 1 1h1c.55 0 1-.45 1-1s-.45-1-1-1ZM26 7h-1c-.55 0-1 .45-1 1s.45 1 1 1h1c.55 0 1-.45 1-1s-.45-1-1-1ZM16 23c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1s1-.45 1-1v-3c0-.55-.45-1-1-1ZM20.5 23c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1s1-.45 1-1v-3c0-.55-.45-1-1-1ZM11.5 23c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1s1-.45 1-1v-3c0-.55-.45-1-1-1Z" />
  </svg>
);
export default SvgIcons8SmokeDetector;
