const SvgIcons8Building = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M7 49h36V3c0-1.1-.9-2-2-2H9c-1.1 0-2 .9-2 2v46z"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M13 7h9v6h-9zM28 7h9v6h-9zM13 17h9v6h-9zM28 17h9v6h-9zM13 27h9v6h-9zM28 27h9v6h-9zM13 37h9v6h-9zM28 37h9v12h-9z"
    />
  </svg>
);
export default SvgIcons8Building;
