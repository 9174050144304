const SvgIcons8Switchboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="M47 43H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h44a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2zM25 13v24"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <circle fill="currentColor" cx={33} cy={22} r={2} />
    <circle fill="currentColor" cx={38} cy={28} r={2} />
    <circle fill="currentColor" cx={43} cy={22} r={2} />
    <path
      fill="currentColor"
      d="M7 18v10a2 2 0 0 0 2 2V18h6v12a2 2 0 0 0 2-2V18a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2z"
    />
    <path
      d="M14 32h-4l-1-3V18h6v11zM15 29H9"
      style={{
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);
export default SvgIcons8Switchboard;
