const Icons8RegisterSimple = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 48 48",
      stroke: "currentColor",
    }}
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="currentColor"
      d="M37 32.19v7.31c0 .83-.67 1.5-1.5 1.5h-23c-.83 0-1.5-.67-1.5-1.5v-31c0-.83.67-1.5 1.5-1.5H24v8.5c0 2.48 2.02 4.5 4.5 4.5h2.31l3-3H28.5c-.83 0-1.5-.67-1.5-1.5v-10c0-.42-.17-.79-.44-1.06S25.92 4 25.5 4h-13C10.02 4 8 6.02 8 8.5v31c0 2.48 2.02 4.5 4.5 4.5h23c2.48 0 4.5-2.02 4.5-4.5V29.19l-3 3z"
    />
    <path
      fill="currentColor"
      d="m35.82 14.99-1.47 1.48-9.91-9.91 2.12-2.12 9.95 9.95c-.24.18-.47.38-.69.6zM25.86 25a5.29 5.29 0 0 0-.97 1.83L24.53 28H17.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h8.36zM23.34 35H17.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h5.8l-.15.47c-.26.85-.18 1.75.19 2.53z"
    />
    <path
      fill="currentColor"
      d="M42.16 16.999c-1.202-.814-2.836-.566-3.862.46l-9.567 9.568a2.003 2.003 0 0 0-.498.827L26.5 33.5l5.646-1.733c.312-.096.596-.267.827-.498l9.648-9.648a3.002 3.002 0 0 0-.461-4.622z"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 3,
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m43 17 1-1"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 4,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);
export default Icons8RegisterSimple;
