const SvgIcons8Detector = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    {...props}
  >
    <path fill="none" d="M0 0h50v50H0z" />
    <path fill="currentColor" d="M0 19a6 6 0 1 1 0 12" />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M36.393 39.488A22.072 22.072 0 0 1 31 25a22.072 22.072 0 0 1 5.392-14.487M42.486 35.601A14.724 14.724 0 0 1 38 25c0-4.16 1.72-7.918 4.487-10.602M48.909 31.517A7.383 7.383 0 0 1 45 25a7.385 7.385 0 0 1 3.897-6.511M30.373 43.329C26.383 38.293 24 31.925 24 25s2.383-13.293 6.373-18.329"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M24.283 47.022A36.762 36.762 0 0 1 17 25a36.76 36.76 0 0 1 7.294-22.036"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M1 5v40"
    />
  </svg>
);
export default SvgIcons8Detector;
