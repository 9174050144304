import * as React from "react";
const SvgIcons8SecurityEnergy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="1.5 1.5 28.5 28.5"
    {...props}
  >
    <path d="M16 3.963c-2.25 0-3.758.883-5.115 1.621C9.527 6.322 8.282 6.963 6 6.963H5v1c0 7.718 2.605 12.739 5.246 15.777 2.641 3.039 5.381 4.15 5.381 4.15l.371.15.37-.147s2.744-1.099 5.386-4.131C24.396 20.729 27 15.708 27 7.962v-1h-1c-2.27 0-3.516-.64-4.877-1.378C19.762 4.845 18.25 3.963 16 3.963zm0 2c1.75 0 2.767.617 4.17 1.379a12.528 12.528 0 0 0 4.762 1.455c-.19 6.77-2.427 11.058-4.686 13.65-2.052 2.355-3.766 3.17-4.244 3.383-.48-.215-2.196-1.041-4.248-3.402-2.26-2.6-4.494-6.889-4.684-13.631 2.028-.208 3.59-.815 4.77-1.457 1.4-.762 2.41-1.377 4.16-1.377zM16 8l-4 8h4v6l4-8h-4V8z" />
  </svg>
);
export default SvgIcons8SecurityEnergy;
