const SvgIcons8CountryHouse = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 50 50",
    }}
    viewBox="0 0 50 50"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M44 20v26H6V20h38z"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M13 27h8v12h-8zM29 27h8v12h-8zM35 10.5c.005-.002 0-4.5 0-4.5h4v8M48 22 25 2.053 2 22"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);
export default SvgIcons8CountryHouse;
