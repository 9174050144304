const IconElectrical = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    {...props}
  >
    <circle
      cx="25"
      cy="25"
      r="24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      fill="#000000"
      stroke="currentColor"
      strokeWidth="2"
      transform="translate(-7, -9) scale(1.3)"
      d="m25.592 18.491-4.734 8.284M20.943 26.432h7.876M28.58 26.671l-4.773 7.398"
    />
  </svg>
);
export default IconElectrical;
