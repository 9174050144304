const PlumbingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="50px"
    height="50px"
    {...props}
  >
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      x1="1"
      y1="1"
      x2="13"
      y2="1"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      x1="49"
      y1="33"
      x2="37"
      y2="33"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      x1="25"
      y1="23"
      x2="25"
      y2="11"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      d="M25,12H14.2C13,12,12,11,12,9.8V1H2v8.8C2,16.5,7.5,22,14.2,22H25"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      d="M25,22h10.8c1.2,0,2.2,1,2.2,2.2V33h10v-8.8C48,17.5,42.5,12,35.8,12H25"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      d="M47,45c0,2.2-1.8,4-4,4s-4-1.8-4-4c0-2.2,4-9,4-9S47,42.8,47,45z"
    />
  </svg>
);

export default PlumbingIcon;
