const Icons8SmokeDetectorB = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="m41.333 20 2.909-4L46 9c0-1.657-7.173-6-21-6S4 7.343 4 9l1.736 7 2.931 4M8.667 20l1.167 5.5c0 1.381 7.02 3.5 15.167 3.5s15.167-2.119 15.167-3.5l1.167-5.5"
      style={{
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M41.333 20s-.411-.329-1.265-.799"
      style={{
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: 2,
        strokeLinejoin: "round",
      }}
    />
    <path
      d="M38.32 18.359C35.712 17.255 31.352 16 25 16c-7.133 0-11.755 1.583-14.203 2.757"
      style={{
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: 2,
        strokeLinejoin: "round",
        strokeDasharray: "2.9152,1.9435",
      }}
    />
    <path
      d="M9.932 19.201c-.854.47-1.265.799-1.265.799M40.167 25s-.427-.309-1.299-.742"
      style={{
        fill: "none",
        stroke: "currentcolor",
        strokeWidth: 2,
        strokeLinejoin: "round",
      }}
    />
    <path
      d="M36.931 23.423C34.444 22.494 30.499 21.5 25 21.5c-6.285 0-10.542 1.299-12.908 2.314"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinejoin: "round",
        strokeDasharray: "3.1667,2.1111",
      }}
    />
    <path
      d="M11.132 24.258c-.871.433-1.299.742-1.299.742"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinejoin: "round",
      }}
    />
    <path
      d="M34 44s1-2.013 1-2.75-1-1.691-1-2.75 1-2.157 1-2.75S34 33 34 33M16 44s1-2.013 1-2.75-1-1.691-1-2.75 1-2.157 1-2.75S16 33 16 33M25 47s1-2.196 1-3-1-1.845-1-3 1-2.353 1-3-1-3-1-3"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);
export default Icons8SmokeDetectorB;
