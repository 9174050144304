export { default as Icons8Plumbing } from "./Icon8Plumbing";
export { default as Icons8checkmark } from "./Icon8checkmark";
export { default as IconElectrical } from "./IconElectrical";
export { default as Icons8Apartment } from "./Icons8Apartment";
export { default as Icons8ArrowRight } from "./Icons8ArrowRight";
export { default as Icons8Building } from "./Icons8Building";
export { default as Icons8CityBlock } from "./Icons8CityBlock";
export { default as Icons8CountryHouse } from "./Icons8CountryHouse";
export { default as Icons8Detector } from "./Icons8Detector";
export { default as Icons8Fire } from "./Icons8Fire";
export { default as Icons8Gas } from "./Icons8Gas";
export { default as Icons8Maybe } from "./Icons8Maybe";
export { default as Icons8Property } from "./Icons8Property";
export { default as Icons8RegisterSimple } from "./Icons8RegisterSimple";
export { default as Icons8Residence } from "./Icons8Residence";
export { default as Icons8SecurityEnergy } from "./Icons8SecurityEnergy";
export { default as Icons8SecurityEnergyB } from "./Icons8SecurityEnergyB";
export { default as Icons8SmokeDetector } from "./Icons8SmokeDetector";
export { default as Icons8SmokeDetectorB } from "./Icons8SmokeDetectorB";
export { default as Icons8Switchboard } from "./Icons8Switchboard";
export { default as Icons8Trailer } from "./Icons8Trailer";
