import * as React from "react";
const SvgIcons8SecurityEnergyB = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 48 48",
    }}
    viewBox="0 0 48 48"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M24 43.241C38.848 36.987 40.5 26.754 40.5 20.5v-9c-9.711 0-16.5-7-16.5-7s-6.789 7-16.5 7v9c0 6.254 1.652 16.487 16.5 22.741z"
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: 3,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path d="M30.933 23.173a1 1 0 0 0-.732-.619l-4.75-.769L25.46 15a.997.997 0 0 0-.983-1.073h-.016a.992.992 0 0 0-.814.42l-6.454 9.53a.998.998 0 0 0 .607 1.569l4.75.769L22.541 33a.998.998 0 0 0 1 1.002c.313 0 .616-.148.808-.41l6.461-9.469c.198-.275.245-.633.123-.95z" />
  </svg>
);
export default SvgIcons8SecurityEnergyB;
