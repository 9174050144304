const SvgIcons8Trailer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="2 0 70 70"
    width="1em"
    height="1em"
    {...props}
  >
    <circle
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 3,
        strokeMiterlimit: 10,
      }}
      cx="45"
      cy="58"
      r="8"
    />
    <circle
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 3,
        strokeMiterlimit: 10,
      }}
      cx="45"
      cy="58"
      r="2"
    />
    <circle cx="5" cy="52" r="1" fill="currentColor" />
    <circle cx="9" cy="52" r="1" fill="currentColor" />
    <path
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 3,
        strokeMiterlimit: 10,
      }}
      d="M70,58V37.736l-5.037-13.056C63.178,20.052,58.728,17,53.767,17H12c-4.418,0-8,3.582-8,8v27l0,0c-0.552,0-1,0.448-1,1v4c0,0.552,0.448,1,1,1h32.862"
    />
    <circle cx="13" cy="37" r="1" fill="currentColor" />
    <circle cx="17" cy="37" r="1" fill="currentColor" />
    <circle cx="21" cy="37" r="1" fill="currentColor" />
    <circle cx="25" cy="37" r="1" fill="currentColor" />
    <circle cx="31" cy="52" r="1" fill="currentColor" />
    <circle cx="35" cy="52" r="1" fill="currentColor" />
    <line
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
      x1="53.216"
      y1="58"
      x2="77"
      y2="58"
    />
    <path
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeMiterlimit: 10,
      }}
      d="M11,58V27c0-1.657,1.343-3,3-3h10c1.657,0,3,1.343,3,3v31"
    />
    <path
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeMiterlimit: 10,
      }}
      d="M37,37h18c1.657,0,3-1.343,3-3v-7c0-1.657-1.343-3-3-3H37c-1.657,0-3,1.343-3,3v7C34,35.657,35.343,37,37,37z"
    />
    <line
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
      x1="23"
      y1="42"
      x2="23"
      y2="45"
    />
  </svg>
);

export default SvgIcons8Trailer;
