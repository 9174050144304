import clsx from "clsx";
import {
  IconElectrical,
  Icons8Apartment,
  Icons8Building,
  Icons8CityBlock,
  Icons8CountryHouse,
  Icons8Fire,
  Icons8Maybe,
  Icons8Plumbing,
  Icons8Property,
  Icons8Residence,
  Icons8SmokeDetectorB,
  Icons8Switchboard,
  Icons8Trailer,
} from "src/components/icons";
import { NavItem, NavItemProps } from "~/components/NavItem";

const navItems: NavItemProps[] = [
  {
    name: "Services",
    subItems: [
      {
        name: "Property Compliance Subscription",
        href: "/property-compliance-subscription",
        icon: (
          <Icons8Property className="mr-2 h-8 w-8 flex-shrink-0 group-hover:text-red-500" />
        ),
      },
      {
        name: "Smoke Alarm Safety Check",
        href: "/smoke-alarm-safety-check",
        icon: <Icons8SmokeDetectorB className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
      {
        name: "Electrical Safety Check",
        href: "/electrical-safety-check",
        icon: (
          <IconElectrical className="mr-2 h-8 w-8 flex-shrink-0 group-hover:text-red-500" />
        ),
      },
      {
        name: "Gas Safety Check",
        href: "/gas-safety-check",
        icon: <Icons8Fire className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
      {
        name: "Caravan Gas Compliance Check",
        href: "/caravan-gas-compliance-check",
        icon: <Icons8Trailer className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
      {
        name: "Electrical Switchboard Upgrade",
        href: "/electrical-switchboard-upgrade",
        icon: <Icons8Switchboard className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
      {
        name: "General Plumbing",
        href: "/general-plumbing",
        icon: <Icons8Plumbing className="mr-2 h-8 w-8 flex-shrink-0" />,
      },

      {
        name: "Inclusions & Exclusions",
        href: "/inclusions-exclusions",
        icon: <Icons8Maybe className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
    ],
  },
  {
    name: "Solutions",
    subItems: [
      {
        name: "Property Managers",
        href: "/solutions-for-property-managers",
        icon: <Icons8Apartment className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
      {
        name: "Landlords",
        href: "/solutions-for-landlords",
        icon: <Icons8Residence className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
      {
        name: "Building Managers",
        href: "/solutions-for-building-managers",
        icon: <Icons8Building className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
      {
        name: "Homeowners",
        href: "/solutions-for-homeowners",
        icon: <Icons8CountryHouse className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
      {
        name: "Real Estate",
        href: "/solutions-for-real-estate",
        icon: <Icons8CityBlock className="mr-2 h-8 w-8 flex-shrink-0" />,
      },
    ],
  },
  {
    name: "FAQ",
    href: "/faq",
  },
  {
    name: "About Us",
    href: "/about-us",
  },
  {
    name: "Work with Us",
    href: "/work-with-us",
  },
  {
    name: "Agent Portal",
    href: "https://portal.rcsc.com.au/",
  },
];

type Props = {
  className?: string;
};

const Nav: React.FC<Props> = ({ className }) => {
  return (
    <nav className={clsx("flex", className)}>
      {navItems.map((item) => (
        <NavItem key={item.name} {...item} />
      ))}
    </nav>
  );
};

export { Nav };
