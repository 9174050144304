const SvgIcons8CityBlock = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 50 50",
    }}
    viewBox="0 0 50 50"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M15 26V6h13v8"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M34 40H23V14h13v8"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M43 30.842 49.141 44H.967L7 29.923"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M7 26h16v14H7zM31 22h12v18H31z"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      fill="currentColor"
      d="M34 25h2v2h-2zM31 17h2v2h-2zM26 17h2v2h-2zM23 9h2v2h-2zM18 9h2v2h-2zM18 13h2v2h-2zM18 17h2v2h-2zM18 21h2v2h-2zM26 21h2v2h-2zM26 25h2v2h-2zM26 29h2v2h-2zM26 33h2v2h-2zM10 29h2v2h-2zM14 29h2v2h-2zM18 29h2v2h-2zM10 33h2v2h-2zM14 33h2v2h-2zM18 33h2v2h-2zM38 25h2v2h-2zM34 29h2v2h-2zM38 29h2v2h-2zM34 33h2v2h-2zM38 33h2v2h-2z"
    />
  </svg>
);
export default SvgIcons8CityBlock;
