import { TruckIcon } from "@heroicons/react/20/solid";
import React from "react";

const locations = [
  // "Seaford",

  "Frankston",
  "Carrum Downs",
  // "Patterson Lakes",
  // "Langwarrin",
  "Cranbourne",
  // "Mornington",
  // "Somerville",
  "Dandenong",
  // "Aspendale",
  // "Edithvale",
  "Springvale",
  "Narre Warren",
  "Rowville",
  // "Keysborough",
  // "Mordialloc",
  // "Noble Park",
  // "Hastings",
  // "Pearcedale",
  // "Pakenham",
  // "Mentone",
  // "Cheltenham",
  "Clayton",
  // "Sandringham",
];

interface BarComponentProps {
  backgroundColor?: string;
  textColor?: string;
}

const DistrictBar: React.FC<BarComponentProps> = ({
  backgroundColor = "bg-gray-200",
  textColor = "text-black",
}) => {
  const estimatedMinWidth = locations.length * 146;

  return (
    <>
      <h4 className="mb-2 mt-2 flex items-center justify-center">
        RCSC operates near
      </h4>
      <div
        className={`${backgroundColor} overflow-hidden overflow-x-auto py-2`}
      >
        {" "}
        <div
          className="flex items-center justify-center gap-6 overflow-x-auto"
          style={{ minWidth: `${estimatedMinWidth}px` }}
        >
          {locations.map((location, index) => (
            <div
              key={index}
              className="flex shrink-0 items-center justify-center gap-2 whitespace-nowrap"
            >
              <TruckIcon className="h-5 w-5 text-black" />
              <span className={textColor}>{location}</span>{" "}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DistrictBar;
