const SvgIcons8Residence = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      d="m36 5 12 15H34l-9-9-9 9H2L14 5zM48 20v25H2V20"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M29 36a4 4 0 0 0-8 0v9h8v-9z"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M17 24h-2v21M33 24h2v21"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={25}
      cy={24}
      r={3}
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M39 25h5v5h-5zM39 35h5v5h-5zM6 25h5v5H6zM6 35h5v5H6z"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
);
export default SvgIcons8Residence;
