import * as React from "react";
const SvgIcons8Gas = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    baseProfile="basic"
    viewBox="0 0 64 64"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="m45 22-3 3c1.11.85 10 5.49 10 15 0 9-4.21 13.45-9 17 .92-1.24 4-9-1.73-13.48l-4.37 4.36a9.047 9.047 0 0 1-6.62-8.71c0-.4.03-.79.08-1.17-2.99 3.33-9.36 9-5.06 18.57C19.92 52.24 15 48 15 40c0-15.75 9.2-23.44 17-28 .19 4.93.72 10.67 7 16l3-3c-7.49-6.55-6-22.81-6-24C19 9 7 24.25 7 40c0 12 12 24 27.5 24C47.46 64 56 52.96 56 40c0-11-11-18-11-18z" />
  </svg>
);
export default SvgIcons8Gas;
